<template>
    <div class="container-fluid">
        <VueSlickCarousel v-if="imageSlider.length" v-bind="settings" ref="carousel"> 
            <div v-for="(image, index) of imageSlider" :key="index">
                <img class="slider-image" :src="image.file_url"/>
            </div>
        </VueSlickCarousel>
    </div>
</template>

<script>


export default {
    name: "SliderHome",
    props: {
        imageSlider: {
            type: Array
        }       
    },
    computed: {
    },
    data() {
      return {
        settings: {
            slidesToShow: 1,
            accessibility: true,
            arrows: false,
            adaptiveHeight: true,
            dots: true,
            swipeToSlide: true,
            autoplay: true,
        },
      }
    }
};

</script>
<style scoped>
.container-fluid{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-right: 0px !important;
}
.slider-image {
  max-width: 100%;
  height: auto;
}
</style>