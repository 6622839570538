<template>
    <div id="app">
        <b-container fluid>
            <b-row>
                <sliderHome :imageSlider="filteredImageSlider" />
            </b-row>
            <b-row class="pb-3 pt-md-5 pt-mobile-2">
                <b-col md="12" cols="12" class="padding-personalized">
                    <noteSection />
                </b-col>
            </b-row>
            <!--
            <b-row class="py-3">
                <b-col md="12" cols="12" class="padding-personalized">
                    <categories :categoriesHoyostool="categoriesHoyostool" @item-info="itemInfo($event)" />
                </b-col>
            </b-row>
-->
            <b-row class="pt-2">
                <b-col md="12" cols="12" class="padding-personalized">
                    <itemDayRecommended :items="itemDayRecommendedList" @item-info="itemInfo($event)" />
                </b-col>
            </b-row>
            <b-row class="pt-4 pb-5">
                <b-col md="12" cols="12" class="padding-personalized">
                    <itemsRecommended :items="itemsRecommendedList" @item-info="itemInfo($event)" />
                </b-col>
            </b-row>
            <b-row class="pt-5 image-recomended-section">
                <b-col md="6" cols="12" v-for="(image, index) of filteredImagesRecomended" :key="index" class="image-recomended-wrapper">
                    <img class="image-recomended" :src="image.file_url"/>
                </b-col>
            </b-row>

        </b-container>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import itemsRecommended from './Template/components/Items/RecommendedItems.vue'
import itemDayRecommended from './Template/components/Items/ItemDayRecommended.vue'
//import categories from './Template/components/Items/Categories.vue'
import noteSection from './Template/components/NoteSection.vue'
import sliderHome from './Template/components/ImagesComponent/SliderHome.vue'
import { CATEGORY_RECOMENDED_ITEMS_HOME_WEB, CATEGORY_SLIDER_HOME_WEB } from '@/constants/config/image.js'

export default {
    computed: {
        ...mapState('configuration', ['empresaLogo', 'background', 'categoriesHoyostool', 'imagesConfig']),
        cssVars() {
            return {
                '--border-bottom': this.background
            };
        },
        filteredImagesRecomended() {
            const sortedImages = [...this.imagesConfig].sort((a, b) => a.order - b.order);
            return sortedImages.filter(image => image.category === CATEGORY_RECOMENDED_ITEMS_HOME_WEB);
        },
        filteredImageSlider() {
            const sortedImages = [...this.imagesConfig].sort((a, b) => a.order - b.order);
            return sortedImages.filter(image => image.category === CATEGORY_SLIDER_HOME_WEB);
        }
    },
    components:{
        itemsRecommended,
        itemDayRecommended,
        noteSection,
        sliderHome
    },
    created: function () {
        this.recommendedItems();
        this.dayRecommendedItems();
    },
    data(){
        return{
            itemsRecommendedList: [],
            itemDayRecommendedList: [],
        }
    },
    methods:{
        ...mapMutations(['setLoading']),
        async recommendedItems(){
            this.setLoading(true)
            await this.$conectar.post('/cliente-web/get-recommended-items')
            .then(response =>{
                this.itemsRecommendedList = response.data.items;

            })
            .catch(error => {
                console.log(error);
            })
            this.setLoading(false)
        },
        async dayRecommendedItems(){
            this.setLoading(true)
            await this.$conectar.post('/cliente-web/get-day-recommended-items')
            .then(response =>{
                this.itemDayRecommendedList = response.data.items;

            })
            .catch(error => {
                console.log(error);
            })
            this.setLoading(false)
        },
        itemInfo(datos){
            if(typeof datos.empresa_id !== 'undefined' && datos.empresa_id != 0){
                this.$router.push({ name: 'itemEmpresaDetail', params:{ codigo: datos.codigo} });
            }else{
                this.$router.push({ name: 'ItemDetail', params:{ referencia: datos.codigo} });
            }
        },
    }
}

</script>

<style scoped>
.titulo{
    font-size: 23px;
}

.container-fluid{
    background-color: #f5f5f9;
}

.image-recomended-section {
    padding-right: 10px;
    padding-left: 10px;
}
.image-recomended {
  width: 100%;
  height: auto;
  max-width: 1800px;
  max-height: 225px;
  border-radius: 5px;
  
}
.image-recomended-wrapper {
  margin-bottom: 20px;
}


@media (max-width: 768px) {
  .pt-mobile-2 {
    padding-top: 5px !important;
  }
}

@media (max-width: 480px) {
    .padding-personalized{
        padding-top: 20px !important;
    }
}
</style>