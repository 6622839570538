<template>
    <div class="col-12 pt-1">
        <VueSlickCarousel v-bind="settings" ref="carousel"> 
                <b-card class="h-100">
                    <div>
                        <div class="d-flex align-items-center note-section">
                            <span class="material-icons-outlined note-add-icon mr-4">
                                local_shipping
                            </span>
                            <div>
                                <span class="note-add-titles d-block">
                                    SERVICIO DOMICILIO
                                </span>
                                <span>
                                    Envios a todas las ciudades
                                </span>
                            </div>
                        </div>
                    </div>
                </b-card>
                <b-card class="h-100 ">
                    <div>
                        <div class="d-flex align-items-center note-section">
                            <span class="material-icons-outlined note-add-icon mr-4">
                                savings
                            </span>
                            <div>
                                <span class="note-add-titles d-block">
                                    AHORRA
                                </span>
                                <span>
                                    Ahorra mucho en cada pedido
                                </span>
                            </div>
                        </div>
                    </div>
                </b-card>
                <b-card class="h-100 ">
                    <div>
                        <div class="d-flex align-items-center note-section">
                            <span class="material-icons-outlined note-add-icon mr-4">
                                safety_check
                            </span>
                            <div>
                                <span class="note-add-titles d-block">
                                    COMPRA SEGURA
                                </span>
                                <span>
                                    Tus compras estan seguras
                                </span>
                            </div>
                        </div>
                    </div>
                </b-card>
                <b-card class="h-100 ">
                    <div>
                        <div class="d-flex align-items-center note-section">
                            <span class="material-icons-outlined note-add-icon mr-4">
                                support_agent
                            </span>
                            <div>
                                <span class="note-add-titles d-block">
                                    ATENCIÓN
                                </span>
                                <span>
                                    Equipo dispuesto a ayudarte
                                </span>
                            </div>
                        </div>
                    </div>
                </b-card>
        </VueSlickCarousel>
    </div>
</template>

<script>

import { mapState } from 'vuex'
export default {
    name: "noteSection",
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('configuration', ['background']),
    },
    data() {
      return {
        settings: {
            slidesToShow: 4,
            arrows: false,
            swipeToSlide: true,
            responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                }
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }]
        },
      }
    },
};

</script>
<style scoped>
.note-section{
    cursor: pointer;
    min-height: 100px;
}

.card{
    border-radius: 0px;
    
}

.note-add-titles{
    color: #2b4861;
    font-weight: 600;
    font-size: 17px;
}

.note-add-icon{
    color: #f04706 !important;
    font-size: 40px;
    transition: transform 0.3s ease;
}

.card:hover .note-add-icon  {
  transform: scaleX(-1);
}

.category-title{
    font-size: 18px;
    font-weight: bold;
    color: #2b4861;
}

@media (max-width: 1024px) {
    .category-title{
        height: 150px !important;
    }
    .title{
        font-size: 18px;
    }
}

@media (max-width: 768px) {
    .title{
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .title{
        font-size: 16px !important;
    }
}


</style>