<template>
    <b-container>
        <b-modal
            size="lg"
            v-model="openModal"
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            >
            
            <template v-slot:modal-title>
                <span class="modal-title">AGREGAR AL CARRITO </span>
            </template>
            <b-container>
                <b-row>
                    <b-col cols="5">
                        <b-card-img :src="item.itemImage" class="pb-0" alt="Imagen del item"></b-card-img>
                    </b-col>
                    <b-col class="pr-1">
                        <div class="item-info">
                            <b><span class="pt-0 item-name">{{capitalizeFirstLetterOfAllWordsExceptFirst(item.itemName)}}</span></b>
                        </div>
                        <div class="pt-5">
                            <span class="text-center">
                                <strong>Cantidad</strong>
                            </span>
                            <span>
                                <SpinButton 
                                    :value="itemQuantity"
                                    @input="itemQuantity = $event"/>
                            </span>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
            <template v-slot:modal-footer>
                <div>
                <b-button variant="danger" class="mr-2"  @click.prevent="closeModal">
                    Cancelar
                </b-button>
                <b-button variant="info" @click.prevent="addItemToCart(item)">
                    Agregar
                </b-button>
                </div>
            </template>
        </b-modal>
    </b-container>
</template>
<script>
import SpinButton from '@/components/GlobalComponents/SpinButton.vue'
import { mapActions, mapMutations } from 'vuex'
export default {
    props: {
        item: {
            type: Object
        },
        openModal: {
            type: Boolean
        }
    },
    components:{
        SpinButton
    },
    data(){
        return{
            itemQuantity: 1,
        }
    },
    methods:{
         ...mapActions('configuration', {
          _getCartCount: 'getCartItemsCountByUser',
        }),
        ...mapMutations(['setLoading']),
        closeModal(){
            this.itemQuantity = 1;
            this.$emit('close-modal');
        },
        async addItemToCart(itemData){
            this.setLoading(true)
            this.$emit('close-modal')
            if(itemData.empresa_id){
                let codigo = itemData.reference;
                let cantidad = this.itemQuantity;
                let data = {codigo, cantidad}
                await this.$conectar.post('/articulos/add-item-empresa-to-cart', data)
                .then(response =>{
                    if (response.data.error == false) { 
                        this.$bvToast.toast(response.data.mensaje, {
                            title: "Respuesta del servidor",
                            variant: "success",
                            solid: true
                        })
                        this._getCartCount();
                    }else{
                        this.$bvToast.toast(response.data.mensaje, {
                            title: "Respuesta del servidor",
                            variant: "danger",
                            solid: true
                        })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast('Parece que hubo un error en el servidor', {
                        title: "Respuesta del servidor",
                        variant: "danger",
                        solid: true
                    })
                    console.log(error);
                })
            }else{
                let referencia = itemData.reference;
                let cantidad = this.itemQuantity;
                let quantity = itemData.quantity;
                let enviar = {referencia, cantidad, quantity }
                await this.$conectar.post('/articulos/add-item-to-cart', enviar)
                .then(response =>{
                    if (response.data.error == false) { 
                        this.$bvToast.toast(response.data.mensaje, {
                            title: "Respuesta del servidor",
                            variant: "success",
                            solid: true
                        })
                        this._getCartCount();
                    }else{
                        this.$bvToast.toast(response.data.mensaje, {
                            title: "Respuesta del servidor",
                            variant: "danger",
                            solid: true
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$bvToast.toast('Parece que hubo un error en el servidor', {
                        title: "Respuesta del servidor",
                        variant: "danger",
                        solid: true
                    })
                })
            }
            this.itemQuantity = 1;
            this.$emit('close-modal');
            this.setLoading(false)
        },
        
    }
}
</script>
<style scoped>
.modal-title{
    font-size: 18px;
    font-weight: 500;
}

.card-img { 
    max-width: 100%; 
    max-height: 150px;
    object-fit: scale-down;
}
.item-name{
    font-size: 16px;
    color: #273c99 !important;
}
</style>