<template>
    <b-input-group>
      <b-input-group-prepend>
        <b-button variant="outline-dark" class="py-0" size="sm" @click="valueChange(value - 1)">
          <b-icon icon="dash" font-scale="1.6" />
        </b-button>
      </b-input-group-prepend>
  
      <b-form-input
        :size="size"
        :value="value"
        type="number"      
        min="1"
        class="border-secondary text-center"
        number
        oninput="validity.valid||(value=1);"
        @change="valueChange"
      />
  
      <b-input-group-append>
        <b-button variant="outline-dark" class="py-0" size="sm" @click="valueChange(value + 1)">
          <b-icon icon="plus" font-scale="1.6" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </template>
  
<script> 
    export default {
        name: 'SpinButton',
        props: {
            size: {
                type: String,
                required: false,
                default: 'md',
                validator: function (value) {
                return ['sm', 'md', 'lg'].includes(value)
                }
            },
            value: {
                required: true
            }
        },
        methods: {
        valueChange (newValue) {
            if (newValue < 1) {
                this.$emit('input', 1)
            } else {
                this.$emit('input', newValue)
            }
        }
        }
    }
</script>
  
<style scoped>
    /* Remove up and down arrows inside number input */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    .input-group{
      max-width: 180px;
    }

    .btn{
      transition: 0.8s;
    }

    .btn:hover{
      background-color: #b00b3c;
    }
</style>